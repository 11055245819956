#js-prayer-time-table td,
#js-prayer-time-table th {
    padding: 0.3rem;
    vertical-align: middle;
}

#js-prayer-time-table th span {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal !important;
    margin-top: -5px;
    margin-bottom: -5px;
    color: darkgray;
    font-weight: 400!important;
}

#js-prayer-time-table {
    margin-bottom: 0rem;
}