.live-container {
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
}

.live-dot-container {
    height: 100%;
    margin-top:auto;
    margin-bottom: auto;
}

.live-dot {
    display: flex;
   width:10px;
   height:  10px;
   background-color: red;
   border-radius: 50%;
   margin-right: 5px;
   animation: blink 2s infinite;
}

.live-label {
    text-align: center;
}

@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}


