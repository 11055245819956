.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.establishment-name {
    flex: 1;
    text-align: center;
}

.establishment-dropdown {
    margin-left: 1rem;
    flex-grow: 1;
}

.establishment-dropdown button {
    width: 100%;
    white-space: pre-wrap;
    font-size: 18px;
}

  
.establishment-dropdown .dropdown-menu {
    width: 100%
}

.app-container {
    margin-bottom: 90px;
}


  