.next-salah-container {
    text-align: center;
}

#js-next-prayer {
    padding-right: 5px;
    display: inline-block;
}

#js-next-prayer-from-now {
    display: inline-block;
}